import React from "react";
import "./BottomNav.css";

function BottomNav() {
  return (
    <div className="bottom-nav">
      <div className="bottom-nav-left">
        <div className="bottom-nav-title">
          <a href="/" className="bottom-top-nav-link">
            Daily Tarot Reader
          </a>
        </div>
        {/* <div className="bottom-nav-trademark">&#8482;</div> */}
      </div>
      <div className="bottom-nav-right">
        <a href="/about" className="bottom-nav-link">
          About
        </a>
        <a href="/support" className="bottom-nav-link">
          Support
        </a>
        <a href="https://dailytarotreaderblog.com/" className="bottom-nav-link">
          Blog
        </a>
      </div>
    </div>
  );
}

export default BottomNav;
