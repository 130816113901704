import "./Support.css";

export default function Support() {
  return (
    <div className="support-container">
      <h1 className="support-header">Support</h1>
      <p className="support-text">
        We're here to help! If you have any questions, issues, or feedback about
        Daily Tarot Reader, please don't hesitate to reach out to us at{" "}
        <a
          className="support-email"
          href="mailto:dailytarotreaderhelp@gmail.com"
        >
          dailytarotreaderhelp@gmail.com
        </a>
        . We'll do our best to get back to you within 24 hours.{" "}
        <span role="img" aria-label="smiling-face">
          😊
        </span>
      </p>
      <div className="support-faq-container">
        <h2 className="support-faq-header">FAQ</h2>
        <ul className="support-faq-list">
          <li>
            <h4 className="support-faq-question">
              Q: How often can I get a tarot reading?
            </h4>
            <p className="support-faq-answer">
              A: You can get one tarot reading per day.
            </p>
          </li>
          <li>
            <h4 className="support-faq-question">
              Q: How are the tarot readings generated?
            </h4>
            <p className="support-faq-answer">
              A: Our tarot readings are randomly generated using a custom
              algorithm. They are not based on personal information or previous
              readings.
            </p>
          </li>
          <li>
            <h4 className="support-faq-question">
              Q: Can I request a specific tarot card for my reading?
            </h4>
            <p className="support-faq-answer">
              A: No, our algorithm randomly generates the tarot cards for each
              reading.
            </p>
          </li>
          <li>
            <h4 className="support-faq-question">
              Q: Can I save my tarot readings?
            </h4>
            <p className="support-faq-answer">
              A: Yes, you can save your tarot readings by taking a screenshot or
              saving the image provided at the end of each reading.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}
