import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "./Swiper.css";
import { EffectCards, Navigation } from "swiper";

export default function SwiperComponent({ images }) {
  return (
    <>
      <Swiper
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards, Navigation]}
        className="mySwiper swiper-mobile"
      > 
        {images.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <img 
                src={image}
                loading="lazy" 
                alt="Tarot Card" />
              <div className="swiper-lazy-preloader"></div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
