import React from "react";
import ReactCardFlip from "react-card-flip";
import "../App.css";
import ButtonContainer from "../components/ButtonContainer";
// import Countdown from "../components/Countdown";
import FAQ from "../components/FAQ";
import Interpretation from "../components/Interpretation";
import Share from "../components/Share";
import Options from "../components/Options";
import TarotCard from "../components/TarotCard";
import TarotDownload from "../components/TarotDownload";
import ToolTip from "../components/ToolTip";
import background1 from "../images/backgrounds/background.jpeg";
// import Timer from "../components/Timer";
import { useTarotReducer } from "../TarotReducer";
import Setup from "../components/Setup";
function Home() {
  //TODO: Add more decks
  //TODO: Add more backgrounds

  const {
    state,
    handleSetup,
    handleStartClick,
    // handleTarotReadingSetup,
    handleTarotCardClick,
    handleRevealedTarotCardClick,
    handleToggleFAQ,
    handleToggleOptions,
    handleToggleShare,
    handleBackgroundChange,
    handleTarotDeckChange,
    changeCardBackImage,
    handleCardBorderColorChange,
    handleExpand,
    buttonIsOpaque,
    placeholderOneRef,
    placeholderTwoRef,
    placeholderThreeRef,
    cardOneRef,
    cardTwoRef,
    cardThreeRef,
  } = useTarotReducer();

  // useEffect(() => {
  //   console.log("State.setup", state.setup)
  //   if(!localStorage.getItem("userState" && !state.setup)) {
  //     console.log("HERE!!!")
  //     console.log("State", state)
  //     handleTarotReadingSetup();
  //   }
  // }, [handleTarotReadingSetup, state.setup]);
    console.log("State", state)
  return (  
    <div
      style={{
        transition: 'height 0.5s ease-in-out, width 0.5s ease-in-out',
        backgroundImage: `url(${
          state.currentBackground ? state.currentBackground : background1
        })`,
      }}
      className="App"
    >
    {/* <button onClick={handleFetchTarotReadings}>Get Tarot Readings</button> */}
      {/* <h1 className="title">Welcome to Daily Tarot Reader</h1> */}
      {/* <Timer /> */}
      {/* <Countdown
        expand={
          (state && state.expand[0]) ||
          (state && state.expand[1]) ||
          (state && state.expand[2])
        }
        state={state}
      /> */}

      {state.setup ? (
        <>
          <h1 className="title">Welcome to Daily Tarot Reader</h1> 
          <Setup 
            state={state}
            changeCardBackImage={changeCardBackImage}
            handleTarotDeckChange={handleTarotDeckChange}
            // handleToggleOptions={handleToggleOptions}
            handleSetup={handleSetup}
          />
        </>
      )
    
      : 
        <>
        <div>
       
       <ReactCardFlip isFlipped={!state.isOuterFlipped}>
         <>
           {state.toggleFAQ && (
             <FAQ handleToggleFAQ={handleToggleFAQ} state={state} />
           )}
           {state.toggleInterpretation && (
             <Interpretation
               handleStartClick={handleStartClick}
               state={state}
             />
           )}

           {state.toggleOptions && (
             <Options
               state={state}
               changeCardBackImage={changeCardBackImage}
               handleTarotDeckChange={handleTarotDeckChange}
               handleBackgroundChange={handleBackgroundChange}
               handleCardBorderColorChange={handleCardBorderColorChange}
               handleToggleOptions={handleToggleOptions}
               // currentDeck={currentDeck}
             />
           )}
           {state.toggleShare && (
               <Share 
                   state={state}
                   handleToggleShare={handleToggleShare}
               />
           )}
         </> 
            <div className="card-container">
              <ToolTip state={state} />
              <TarotCard
                state={state}
                index={0}
                handleRevealedTarotCardClick={handleRevealedTarotCardClick}
                handleTarotCardClick={handleTarotCardClick}
                placeholderRef={placeholderOneRef}
                tarotCardRef={cardOneRef}
                expand={state.expand[0]}
                expandClassName={state.expandClassName}
                handleExpand={handleExpand}
                opacity={buttonIsOpaque()[0]}
              />
              <TarotCard
                state={state}
                index={1}
                handleRevealedTarotCardClick={handleRevealedTarotCardClick}
                handleTarotCardClick={handleTarotCardClick}
                placeholderRef={placeholderTwoRef}
                tarotCardRef={cardTwoRef}
                expand={state.expand[1]}
                expandClassName={state.expandClassName}
                handleExpand={handleExpand}
                opacity={buttonIsOpaque()[1]}

              />
              <TarotCard
                state={state}
                index={2}
                handleRevealedTarotCardClick={handleRevealedTarotCardClick}
                handleTarotCardClick={handleTarotCardClick}
                placeholderRef={placeholderThreeRef}
                tarotCardRef={cardThreeRef}
                expand={state.expand[2]}
                expandClassName={state.expandClassName}
                handleExpand={handleExpand}
                opacity={buttonIsOpaque()[2]}
              />
            </div>
          </ReactCardFlip>
        </div>

        <ButtonContainer
          state={state}
          handleStartClick={handleStartClick}
          handleToggleFAQ={handleToggleFAQ}
          handleToggleOptions={handleToggleOptions}
          handleToggleShare={handleToggleShare}
          expand={state.expand[0] || state.expand[1] || state.expand[2]}
        />
        <TarotDownload state={state} />
        {/* {localStorage.getItem("userState") && (
          <h1 className="bottom-greeting">
            Come back tomorrow for your next reading!
          </h1>
        )} */}
        </>
      }
      
    </div>
  );
}

export default Home;
