export default function FAQ({ state, handleToggleFAQ}) {
  return (
    <div className="faq-container">
      <div onClick={() => handleToggleFAQ()} className="close-button">X</div>
      <h1 className="faq-header">Frequently Asked Questions</h1>
      {state.FAQ &&
        state.FAQ.map((question, index) => {
          return (
            <div
              key={`${question.Q} - ${index}`}
              className="question-container"
            >
              <div className="question-row">
                <p className="question-q">Q:</p>
                <p className="question">{question.Q}</p>
              </div>
              <div className="question-row">
                <p className="answer-a">A:</p>

                <p className="answer answer-left">{question.A}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
}
