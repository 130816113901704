// import axios from "axios";

// const openaiApiKey = process.env.REACT_APP_OPENAI_API_KEY;

export const fetchTarotReadings = async (prompt) => {
  const url = process.env.REACT_APP_TAROT_READINGS_URL;
  // "Pretend you are a mystic and you specialize in three card tarot readings. Give a detailed description of the five of swords and what this card means. The image provided is the tarot card being used. Ensure the reading concludes with a clear and complete sentence."
  // try {

    // if(prompt.url) {
    //   const response = await axios.post(
    //     "https://api.openai.com/v1/chat/completions",
    //     {
    //       model: "gpt-4-vision-preview",
    //       messages: [
    //         {
    //           role: "user",
    //           content: [
    //             { type: "text", text: prompt.text},
    //             {
    //               type: "image_url",
    //               image_url:
    //                 prompt.url,
    //             }
    //           ]
    //         }
    //       ],
    //       max_tokens: 400,
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${openaiApiKey}`,
    //       },
    //     }
    //   );
    //     console.log("REPONSE: URL", response)
    //   return response.data.choices[0].message.content;
    // } else if(prompt.image) {
    //   const response = await axios.post(
    //     "https://api.openai.com/v1/images/generations",
    //     { model: "dall-e-3", prompt: prompt.text },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${openaiApiKey}`,
    //       },
    //     }
    //   )

    //   console.log("RESPONSE: IMAGE", response)
    //   return response.data.data[0].url
    // } else {
    //   const response = await axios.post(
    //     "https://api.openai.com/v1/completions",
    //     {
    //       prompt: prompt.text,
    //       max_tokens: prompt.tokens,
    //       model: "gpt-3.5-turbo-instruct",
    //       temperature: 0.7,
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${openaiApiKey}`,
    //       },
    //     }
    //   );
    //   console.log("REPONSE: TEXT", response)
    //   return response.data.choices[0].text;
    // }

    const retryCount = 3;
    let continueFetch = true;
  for (let attempt = 1; attempt <= retryCount; attempt++) {
    if(!continueFetch) break;
    try {
      const response = await fetch(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prompt }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      console.log("DATA:", data)
      if(data){
        continueFetch = false;

        return data.msg
        // if(prompt.url) {
        //   return data.data.choices[0].text
        // } else if (prompt.image) {
        //   return data.data.data[0].url
        // } else {
        //   return data.choices[0].message.content
        // }
      }
     
      // const data = await response.json();
      // const tarotReadingSplit = data.msg.split("\n");
      // const tarotReadingSanitized = tarotReadingSplit.filter(
      //   (text) => text.trim() !== ""
      // );
      // const tarotReading = tarotReadingSanitized.join(" ");
      // return tarotReading;
    } catch (error) {
      console.error("Error fetching tarot readings:", error.message);
      if (attempt === retryCount) {
        throw new Error(`Failed after ${retryCount} attempts: ${error.message}`);
      }
      await new Promise(resolve => setTimeout(resolve, attempt * 1000)); // Increasing delay between retries
    }
  }
};

// export const fetchTarotReadings = async (prompt) => {
//   const url = process.env.REACT_APP_TAROT_READINGS_URL;

//   try {
//     const response = await fetch(
//       url,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ prompt }),
//       }
//     );

//     if (!response.ok) {
//       throw new Error(`Error: ${response.status} - ${response.statusText}`);
//     }
//     const data = await response.json();
//     const tarotReadingSplit = data.msg.split("\n");
//     const tarotReadingSanitized = tarotReadingSplit.filter(
//       (text) => text.trim() !== ""
//     );
//     const tarotReading = tarotReadingSanitized.join(" ");
//     return tarotReading;
//   } catch (error) {
//     console.error("Error fetching tarot readings:", error.message);
//   }
// };
