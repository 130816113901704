import SwiperComponent from "./Swiper";
// import { useEffect, useState } from "react";
// import { ORIGINAL_DECK, MAGICAL_FOREST_DECK, ETERNAL_BONES_DECK} from "../constants";

export default function Setup({
  state,
  changeCardBackImage,
  handleTarotDeckChange,
  handleSetup
//   handleToggleOptions,
}) {

    // useEffect(() => {
    //     if (state.currentTarotDeck === "original_deck") {
    //         setMinifiedDeck(ORIGINAL_DECK);
    //     } else if (state.currentTarotDeck === "magical_forest_deck") {
    //         setMinifiedDeck(MAGICAL_FOREST_DECK);
    //     } else if (state.currentTarotDeck === "eternal_bones_deck") {
    //         setMinifiedDeck(ETERNAL_BONES_DECK);
    //     } 
    // }, [state.currentTarotDeck]);

  return (
    <div className="setup-container">
      {/* <div onClick={() => handleToggleOptions()} className="close-button">
        X
      </div> */}
      {/* <div className="swiper-button-next button-next right">{">"}</div>
      <div className="swiper-button-prev button-prev left">{"<"}</div> */}
        <h1 className="setup-header">Choose your Tarot Deck</h1>        
        <select
            className="select"
            value={state.currentTarotDeck}
            onChange={(e) => handleTarotDeckChange(e.target.value)}
          >
            <option value="original_deck">Standard</option>
            <option value="magical_forest_deck">Magical Forest</option>
            <option value="eternal_bones_deck">Eternal Bones</option>
          </select>
          <br/>
            <br/>
      <div className="options-row">
        <img
          style={{
            border: "6px solid transparent",
            borderImage: state.cardBorderColor,
          }}
          className="setup-tarot-back options-tarot-back"
          src={changeCardBackImage(state.currentTarotDeck).back}
          alt="tarot back"
        ></img>

        <div
          style={{
            // marginLeft: "40px",
            // marginRight: "20px",
            textAlign: "center",
          }}
          className="options-column"
        >

          {/* <p className="option">Tarot Deck</p> */}
        
          <br />
        </div>
        <div className="options-column">
            <div style={{ width: '233px', height: '384px', overflow: 'hidden'}}>
                <SwiperComponent images={state.currentDeck}/>
            </div>
            <div className="options-row swiper-button-row">
                <div className="swiper-button-prev swiper-button button-action">{"<"}</div>
                <div className="swiper-button-next swiper-button button-action">{">"}</div>
            </div>
        </div>
      </div>
      <button onClick={() => handleSetup()} style={{ marginTop: '30px', marginBottom: '30px'}} className="button">Continue</button>
    </div>
  );
}
