import ReactCardFlip from "react-card-flip";
import React from "react";
import { useIsMobile } from "../hooks/useIsMobile";
export default function TarotCard({
  state,
  index,
  placeholderRef,
  tarotCardRef,
  handleTarotCardClick,
  handleRevealedTarotCardClick,
  expand,
  expandClassName,
  handleExpand,
  opacity
}) {
  // console.log("State in TarotCard", state)
  function currentCardImage() {
    if (index === 0) {
      return state.cardOne;
    } else if (index === 1) {
      return state.cardTwo;
    } else if (index === 2) {
      return state.cardThree;
    }
  }

  const str = state.cardBorderColor && state.cardBorderColor;
  const newBackgroundGradient = str.replace(" 1", "");
  const isMobile = useIsMobile();

  const formatCardName = (name) => {
    const words = name.match(/[A-Z][a-z]*/g);
    const separatedWords = words.join(" ");
    return separatedWords
  }

  //TODO Need to fix bug on mobile where the the middle card is expanded and trys to un-expand it ends up in wrong location
  //PATCHED for now, but still a little wonky -> would like to circle back
  return (
    <div 
    style={{opacity: isMobile && ((state.toggleOptions || state.toggleFAQ || state.toggleInterpretation || state.toggleShare) && 0), transition: 'opacity 0.2s ease-in-out',  position: isMobile && 'relative', zIndex: isMobile && 10}}
    className="tarot-card-column">
      <img
        ref={placeholderRef}
        style={{
          display: state.activeCards[index] && "none",
          border: "8px solid transparent",
          borderImage: state.cardBorderColor,
          opacity:
            (expandClassName[0] || expandClassName[1] || expandClassName[2]) &&
            expandClassName[index] === ""
              ? 0
              : 1,
          pointerEvents:
            (expandClassName[0] || expandClassName[1] || expandClassName[2]) &&
            expandClassName[index] === "" &&
            "none",
        }}
        className={`card one`}
        src={state.currentTarotBack[index]}
        alt={"tarot card back"}
        onClick={() => handleTarotCardClick(index)}
      ></img>
      <ReactCardFlip
        isFlipped={state.isFlipped[index]}
        flipDirection="vertical"
      >
        <img
          ref={tarotCardRef}
          style={{
            display: !state.activeCards[index] && "none",
            border: "8px solid transparent",
            borderColor: 'white', //for ipad
            borderImage: state.cardBorderColor,
            opacity:
              (expandClassName[0] ||
                expandClassName[1] ||
                expandClassName[2]) &&
              expandClassName[index] === ""
                ? 0
                : 1,
            pointerEvents:
              (expandClassName[0] ||
                expandClassName[1] ||
                expandClassName[2]) &&
              expandClassName[index] === "" &&
              "none",
            zIndex: 10
          }}
          className={`card one ${expandClassName[index]} ${index === 0 && "card-left-expand"} ${index === 1 && "card-center-expand"} ${index === 2 && "card-right-expand"}`}
          src={currentCardImage()}
          alt={"tarot card"}
          onClick={() => handleRevealedTarotCardClick(index)}
        ></img>

        <div
          className={`card card-revealed ${expandClassName[index]} ${index === 0 && "card-left-expand"} ${index === 1 && "card-center-expand"} ${index === 2 && "card-right-expand"}`}
          style={{
            display: !state.activeCards[index] && "none",
            // display: (!state.activeCards[index] || !state.isFlipped[index]) && "none",
            opacity: !state.isFlipped[index] && 0,
            border: "solid 6px #E8BA7C",
            background: newBackgroundGradient,
            transition: "opacity 0.2s ease-in-out",
            overflowY: 'scroll',
          }}
          onClick={() => handleRevealedTarotCardClick(index)}
        >
          <h1 className="card-name">
            {state.cardOne && formatCardName(state.currentCardNames[index])}
          </h1>
          {state.cardKeywords &&
            state.cardKeywords[index].map((keyword, index) => {
              return (
                <h2 key={index} className="card-keyword">
                  {keyword}
                </h2>
              );
            })}
          <div className="card-meaning">{state.generatedReading.length === 5 ? state.generatedReading[index + 1] : state.cardMeanings[index]}</div>
        </div>
      </ReactCardFlip>
      <button
        disabled={(state.isFlipped[index] || opacity) && true}
        style={{
          opacity: (!state.activeCards[index] || opacity) && 0,
          pointerEvents: !state.activeCards[index] && "none",
          transition: "opacity 0.2s ease-in-out",
        //   zIndex: -1
        }}
        onClick={() => handleExpand(index)}
        className="mobile-expand-button"
      >
        Expand
      </button>
    </div>
  );
}
