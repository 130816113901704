import background1 from "../images/backgrounds/background.jpeg";
import background2 from "../images/backgrounds/background2.jpeg";
import { BORDER_GRADIENTS } from "../constants";
import SwiperComponent from "./Swiper";
import { useEffect, useState } from "react";
import { ORIGINAL_DECK, MAGICAL_FOREST_DECK, ETERNAL_BONES_DECK} from "../constants";
import { useIsMobile } from "../hooks/useIsMobile";

export default function Options({
  state,
  changeCardBackImage,
  handleTarotDeckChange,
  handleBackgroundChange,
  handleCardBorderColorChange,
  handleToggleOptions,
}) {

    const isMobile = useIsMobile();
    const [minifiedDeck, setMinifiedDeck] = useState(ORIGINAL_DECK);

    useEffect(() => {
        if (state.currentTarotDeck === "original_deck") {
            setMinifiedDeck(ORIGINAL_DECK);
        } else if (state.currentTarotDeck === "magical_forest_deck") {
            setMinifiedDeck(MAGICAL_FOREST_DECK);
        } else if (state.currentTarotDeck === "eternal_bones_deck") {
            setMinifiedDeck(ETERNAL_BONES_DECK);
        } 
    }, [state.currentTarotDeck]);

  return (
    <div className="options-container">
      <div onClick={() => handleToggleOptions()} className="close-button">
        X
      </div>
      <div className="swiper-button-next button-next right">{">"}</div>
      <div className="swiper-button-prev button-prev left">{"<"}</div>

      <div className="options-row">
        <img
          style={{
            border: "6px solid transparent",
            borderImage: state.cardBorderColor,
          }}
          className="options-tarot-back"
          src={changeCardBackImage(state.currentTarotDeck).back}
          alt="tarot back"
        ></img>

        <div
          style={{
            // marginLeft: "40px",
            // marginRight: "20px",
            zIndex: 10,
            textAlign: "center",
          }}
          className="options-column"
        >
          <h1 className="options-header">Options</h1>

          <p className="option">Card Border Color</p>
          <select
            id=" border-select"
            className="select border-select"
            value={state.cardBorderColor}
            onChange={(e) => {
              handleCardBorderColorChange(e.target.value);
            }}
          >
            {BORDER_GRADIENTS.map((gradient, index) => {
              return (
                <option key={index} value={gradient.gradient}>
                  {gradient.name}
                </option>
              );
            })}
          </select>

          <p className="option">Tarot Deck</p>
          <select
            className="select"
            value={state.currentTarotDeck}
            onChange={(e) => handleTarotDeckChange(e.target.value)}
          >
            <option value="original_deck">Standard</option>
            <option value="magical_forest_deck">Magical Forest</option>
            <option value="eternal_bones_deck">Eternal Bones</option>
          </select>

          <p className="option">Background</p>
          <select
            id="background-select"
            className="select"
            value={state.currentBackground}
            onChange={(e) => handleBackgroundChange(e.target.value)}
          >
            <option value={background1}>Background One</option>
            <option value={background2}>Background Two</option>
          </select>
          <br />
        </div>
        <div style={{height: '500px', width: '350px'}}>
           <SwiperComponent images={isMobile ? minifiedDeck : state.currentDeck}/>

        </div>
      </div>
    </div>
  );
}
