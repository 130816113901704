import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const getTodaysDate = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
};

export const downloadAsWord = (content) => {
  const pageContent = content.join("\n\n");
  const pageContentWithTitleAndDate = `Daily Tarot Reader - ${getTodaysDate()}\n\n${pageContent}`;
  const blob = new Blob([pageContentWithTitleAndDate], {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });
  saveAs(blob, `daily-tarot-reader_${getTodaysDate()}.docx`);
};

// export const downloadAsPDF = async (content) => {
//   // Title and content
//   const title = `Daily Tarot Reader - ${getTodaysDate()}`;
//   const pageContent = content.join("\n\n");

//   const doc = new jsPDF();

//   // Set font size and style for the title
//   doc.setFontSize(18);
//   doc.setFont("helvetica", "bold");

//   // Add title to the PDF
//   doc.text(title, 15, 15);

//   // Reset font size and style for the content
//   doc.setFontSize(12);
//   doc.setFont("helvetica", "normal");

//   // Split content into lines that fit the PDF width
//   const lines = doc.splitTextToSize(pageContent, 180);

//   // Add content to the PDF, starting from line 30 (to leave space for the title)
//   doc.text(lines, 15, 25);

//   // Capture the element using html2canvas
//   const element = document.querySelector("#reading-area");
//   const canvas = await html2canvas(element);
//   const imgData = canvas.toDataURL("image/png");

//   // Calculate the image dimensions and position
//   const imgWidth = 130;
//   const imgAspectRatio = canvas.height / canvas.width;
//   const imgHeight = imgWidth * imgAspectRatio;
//   const imgXPosition = 40;
//   const imgYPosition = doc.internal.pageSize.height - imgHeight - 5;

//   // Add the captured image to the PDF
//   doc.addImage(imgData, "PNG", imgXPosition, imgYPosition, imgWidth, imgHeight);

//   // Save the PDF
//   doc.save(`daily-tarot-reader_${getTodaysDate()}.pdf`);

//   return doc;
// };

/**
 * Generates a PDF document with given content and downloads it.
 * @param {string[]} content An array of content strings to include in the PDF.
 */
export const downloadAsPDF = async (content, state) => {
  const title = `Daily Tarot Reader - ${getTodaysDate()}`;
  const pageContent = content.join("\n\n");
  const doc = new jsPDF();

  // Configure title style
  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text(title, 15, 20);

  // Configure content style
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");

  // Split and add content with automatic page management
  const lines = doc.splitTextToSize(pageContent, 180);
  let yPosition = 30; // Start content below the title
  lines.forEach((line) => {
    if (yPosition > 280) { // Check if near the bottom of the page
      doc.addPage();
      yPosition = 10; // Reset Y position for the new page
    }
    doc.text(line, 15, yPosition);
    yPosition += 7; // Adjust Y position for next line
  });

  // Capture and add image
  const element = document.querySelector("#reading-area");
  const canvas = await html2canvas(element);
  const imgData = canvas.toDataURL("image/png");
  const imgWidth = 130;
  const imgAspectRatio = canvas.height / canvas.width;
  const imgHeight = imgWidth * imgAspectRatio;
  
  // Adjust image position based on the last text position
  let imgYPosition = yPosition + 10; // Add some padding after the last text line
  if (imgYPosition + imgHeight > 280) {
    doc.addPage(); // Add a new page if the image doesn't fit
    imgYPosition = 10; // Start at the top of the new page
  }

  doc.addImage(imgData, "PNG", 40, imgYPosition, imgWidth, imgHeight);

  // Save the PDF
  doc.save(`daily-tarot-reader_${getTodaysDate()}.pdf`);

  return doc;
};


export const downloadImageFromHTML = async () => {
  const readingEl = document.querySelector("#reading-area");

  html2canvas(readingEl).then(async (canvas) => {
    // Create a blob from the screenshot data
    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/png")
    );

    const today = new Date();
    const dateString = today.toLocaleDateString("en-US"); // Format: MM/DD/YYYY

    // Share the blob using the Web Share API
    if (navigator.share) {
      navigator.share({
        title: "Tarot Reading",
        files: [
          new File([blob], `DailyTarotReader-${dateString}.png`, {
            type: "image/png",
          }),
        ],
      });
    } else {
      // Fallback for browsers that don't support the Web Share API
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.download = `DailyTarotReader-${dateString}.png`;
      a.href = url;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  });
};
