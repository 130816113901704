import { useEffect, useRef, useReducer, useCallback } from "react";
import {
  ORIGINAL_DECK,
  ORIGINAL_DECK_DICT,
  MAGICAL_FOREST_DECK,
  MAGICAL_FOREST_DECK_DICT,
  ETERNAL_BONES_DECK,
  ETERNAL_BONES_DECK_DICT,
  KEYWORDS,
  TAROT_CARD_MEANINGS,
  TAROT_CARD_TYPES,
  INTERPRETATION_OPTIONS,
  QUESTIONS_AND_ANSWERS,
  CARD_BACKS,
  CARD_SAMPLES,
  // TAROT_DECKS,
  BACKGROUNDS,
  BORDER_GRADIENTS,
} from "./constants";
import { fetchTarotReadings } from "./requests";
function calculateCurrentTime() {
    const dateString = localStorage.getItem("readingEndTime");
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (dateString) {
        // Convert the date string to a Date object
        const lastReadingTime = new Date(dateString);

        // Calculate the difference between the current time and the last reading time
        const currentTime = new Date();
        const timeDiff = currentTime.getTime() - lastReadingTime.getTime();

        // Calculate the remaining time in hours, minutes, and seconds
        hours = Math.floor(timeDiff / (1000 * 60 * 60));
        minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    }
    return { hours, minutes, seconds };
}

const { hours, minutes, seconds } = calculateCurrentTime();

const initialState = localStorage.getItem("userState")
  ? {
      ...JSON.parse(localStorage.getItem("userState")),
      currentTime: { hours: hours, minutes: minutes, seconds: seconds },
    }
  : {
      setup: true,
      // chosenDeck: "original_deck",
      startTextIndex: 0,
      cardOne: null,
      cardTwo: null,
      cardThree: null,
      currentCardNames: {
        0: null,
        1: null,
        2: null,
      },
      cardMeanings: {
        0: "",
        1: "",
        2: "",
      },
      cardKeywords: null,
      activeCards: {
        0: false,
        1: false,
        2: false,
      },
      interpretation: null,
      generatedReading: [], 
      generatedImage: null,
      toggleInterpretation: false,
      FAQ: QUESTIONS_AND_ANSWERS,
      toggleFAQ: false,
      options: null,
      toggleOptions: false,
      activeButton: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
      isFAQButtonEnabled: true,
      isStartButtonEnabled: true,
      isOptionsButtonEnabled: true,
      isShareButtonEnabled: true,
      isFlipped: {
        0: false,
        1: false,
        2: false,
      },
      isOuterFlipped: false,
      currentBackground: BACKGROUNDS[0],
      currentTarotDeck: "original_deck",
      currentDeck: ORIGINAL_DECK,
      currentTarotBack: {
        0: CARD_BACKS.original_deck[0],
        1: CARD_BACKS.original_deck[1],
        2: CARD_BACKS.original_deck[2],
      },
      //   timeRemaining: 0,
      currentTime: {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      },
      startText: {
        0: "Begin Reading",
        1: "Next Card",
        2: "Next Card",
        3: "Interpretation",
      },
      cardBorderColor: BORDER_GRADIENTS[0].gradient,
      hasFlipped: false,
      expand: {
        0: false,
        1: false,
        2: false,
      },
      expandClassName: {
        0: "",
        1: "",
        2: "",
      },
      toggleShare: false,
    };

const randomCard = (deck) => {
  const currentDeck = deck === "original_deck" ? ORIGINAL_DECK : deck === "magical_forest_deck" ? MAGICAL_FOREST_DECK : ETERNAL_BONES_DECK;
  const randomCard =
    currentDeck[Math.floor(Math.random() * currentDeck.length)];
    // debugger;
  return randomCard;
};

const getCardName = (imagePath, deck = false) => {
  // console.log("imagePath: ", imagePath)
  // debugger;
  const currentDeck = deck ? deck : localStorage.getItem("currentTarotDeck");
  const tarotDeckDict = currentDeck === "original_deck" ? ORIGINAL_DECK_DICT : currentDeck === "magical_forest_deck" ? MAGICAL_FOREST_DECK_DICT : ETERNAL_BONES_DECK_DICT;
  const name = Object.keys(tarotDeckDict).find(key => tarotDeckDict[key] === imagePath);

  // console.log("imagePath: ", imagePath)
  // const imageName = imagePath.split("/").pop(); // "/static/media/Eight of Cups.a1ff3600a845e8e71cb6.png";
  // const cardName = imageName.split(".")[0];
  // const cardName = imagePath.split("-")[1].split(".")[0].trim()
  return name;
};

function reducer(state, action) {
  switch (action.type) {
    case "SETUP_TAROT_READING":
      const cards = [randomCard(state.currentTarotDeck), randomCard(state.currentTarotDeck), randomCard(state.currentTarotDeck)];
      // console.log("Cards: ", cards)
      let card1
      let card2
      let card3
      cards.forEach((card, index) => {
        const cardName = getCardName(card, state.currentTarotDeck);
        if(index === 0){
          card1 = cardName
        } else if(index === 1){
          card2 = cardName
        } else if(index === 2){
          card3 = cardName
        }
      });
      const cardNames = [card1, card2, card3]
      // debugger;
      const cardKeywords = cardNames.map((name) => KEYWORDS[name]);
      const cardMeanings = cardNames.map(
        (name) => TAROT_CARD_MEANINGS[name]
      );
      const majorArcanaCount = cardNames.filter(
        (name) => TAROT_CARD_TYPES[name] === "Major Arcana"
      ).length;
      const intro =
        INTERPRETATION_OPTIONS[majorArcanaCount][
          Math.floor(
            Math.random() * INTERPRETATION_OPTIONS[majorArcanaCount].length
          )
        ];
      const interpretationCards = cardNames.map(
        (name) => INTERPRETATION_OPTIONS[name]
      );
      const randomOutro =
        INTERPRETATION_OPTIONS.outros[
          Math.floor(Math.random() * INTERPRETATION_OPTIONS.outros.length)
        ];
      const outro = randomOutro;

      const currentInterpretation = {
        intro: intro,
        cardOne: interpretationCards[0],
        cardTwo: interpretationCards[1],
        cardThree: interpretationCards[2],
        outro: outro,
      };

      return {
        ...state,
        interpretation: currentInterpretation,
        cardOne: cards[0],
        cardTwo: cards[1],
        cardThree: cards[2],
        cardKeywords: cardKeywords,
        cardMeanings: cardMeanings,
        currentCardNames: {
          0: getCardName(cards[0], state.currentTarotDeck),
          1: getCardName(cards[1], state.currentTarotDeck),
          2: getCardName(cards[2], state.currentTarotDeck),
        },
      };
    case "setCards":
      const { newCardOne, newCardTwo, newCardThree } = action.payload;
      return {
        ...state,
        cardOne: newCardOne,
        cardTwo: newCardTwo,
        cardThree: newCardThree,
      };
    case "setCardKeywords":
      return {
        ...state,
        cardKeywords: action.payload,
      };
    case "setCardMeanings":
      return {
        ...state,
        cardMeanings: action.payload,
      };
    case "setInterpretation":
      return {
        ...state,
        interpretation: action.payload,
      };
    case "setToggleInterpretation":
      return {
        ...state,
        toggleInterpretation: action.payload,
      };
    case "setFAQ":
      return {
        ...state,
        FAQ: action.payload,
      };
    case "setSetup":
      return {
        ...state,
        setup: action.payload,
      };
    // case "setChosenDeck":
    //   return {
    //     ...state,
    //     chosenDeck: action.payload,
    //   };
    case "setToggleFAQ":
      return {
        ...state,
        toggleFAQ: action.payload,
      };
    case "setOptions":
      return {
        ...state,
        options: action.payload,
      };
    case "setToggleOptions":
      return {
        ...state,
        toggleOptions: action.payload,
      };
    case "setActiveCards":
      return {
        ...state,
        activeCards: action.payload,
      };
    case "setActiveButton":
      return {
        ...state,
        activeButton: action.payload,
      };
    case "setIsFAQButtonEnabled":
      return {
        ...state,
        isFAQButtonEnabled: action.payload,
      };
    case "setIsStartButtonEnabled":
      return {
        ...state,
        isStartButtonEnabled: action.payload,
      };
    case "setIsOptionsButtonEnabled":
      return {
        ...state,
        isOptionsButtonEnabled: action.payload,
      };
    case "setIsFlipped":
      return {
        ...state,
        isFlipped: action.payload,
      };

    case "setIsOuterFlipped":
      return {
        ...state,
        isOuterFlipped: action.payload,
      };
    case "setCurrentBackground":
      return {
        ...state,
        currentBackground: action.payload,
      };
    case "setCurrentTarotDeck":
      return {
        ...state,
        currentTarotDeck: action.payload,
      };
    case "setCurrentTarotBack":
      return {
        ...state,
        currentTarotBack: action.payload,
      };
    case "setStartTextIndex":
      return {
        ...state,
        startTextIndex: action.payload,
      };
    case "setTimeRemaining":
      return {
        ...state,
        timeRemaining: action.payload,
      };
    case "setCardBorderColor":
      return {
        ...state,
        cardBorderColor: action.payload,
      };
    case "setCurrentTime":
      return {
        ...state,
        currentTime: action.payload,
      };
    case "setHasFlipped":
      return {
        ...state,
        hasFlipped: action.payload,
      };
    case "setExpand":
      return {
        ...state,
        expand: action.payload,
      };
    case "setExpandClassName":
      return {
        ...state,
        expandClassName: action.payload,
      };
      case "setCurrentDeck":
        return {
            ...state,
            currentDeck: action.payload,
        }
      case "setGeneratedReading":
        return {
            ...state,
            generatedReading: action.payload,
        }
        case "setToggleShare":
            return {
                ...state,
                toggleShare: action.payload,
            }
        case "setIsShareButtonEnabled":
            return {
                ...state,
                isShareButtonEnabled: action.payload,
            }
        case "setGeneratedImage":
            return {
                ...state,
                generatedImage: action.payload,
            }
    default:
      return state;
  }
}

export function useTarotReducer() {
  const [state, dispatch] = useReducer(reducer, initialState);
    // console.log("STATE.TOGGLESHARE: ", state.toggleShare)
  const cardOneRef = useRef();
  const cardTwoRef = useRef();
  const cardThreeRef = useRef();

  const placeholderOneRef = useRef();
  const placeholderTwoRef = useRef();
  const placeholderThreeRef = useRef();

  const handleTarotReadingSetup = useCallback(() => {
      dispatch({ type: "SETUP_TAROT_READING" });
  }, []);

  const handleStartClick = () => {
    function setActive(index, ref) {
      dispatch({
        type: "setActiveCards",
        payload: { ...state.activeCards, [index]: true },
      });
      dispatch({
        type: "setStartTextIndex",
        payload: state.startTextIndex + 1,
      });
      ref.current.classList.add("spinning");
      setTimeout(() => {
        ref.current.classList.remove("spinning");
      }, 2000);
    }

    if (state.activeCards[0] === false) {
      setActive(0, cardOneRef);
    } else if (state.activeCards[1] === false) {
      setActive(1, cardTwoRef);
    } else if (state.activeCards[2] === false) {
      setActive(2, cardThreeRef);
      const endTime = new Date();
      localStorage.setItem("readingEndTime", endTime);

      localStorage.setItem(
        "userState",
        JSON.stringify({
          setup: false,
          startTextIndex: 3,
          cardOne: state.cardOne,
          cardTwo: state.cardTwo,
          cardThree: state.cardThree,
          currentCardNames: state.currentCardNames,
          cardMeanings: state.cardMeanings,
          cardKeywords: state.cardKeywords,
          activeCards: [true, true, true],
          interpretation: state.interpretation,
          generatedReading: state.generatedReading,
          generatedImage: state.generatedImage,
          toggleInterpretation: false,
          FAQ: QUESTIONS_AND_ANSWERS,
          toggleFAQ: false,
          options: null,
          toggleOptions: false,
          toggleShare: false,
          activeButton: {
            0: false,
            1: false,
            2: false,
          },
          isFAQButtonEnabled: true,
          isStartButtonEnabled: true,
          isOptionsButtonEnabled: true,
          isShareButtonEnabled: true,
          isFlipped: {
            0: false,
            1: false,
            2: false,
          },
          isOuterFlipped: false,
          currentBackground: BACKGROUNDS[0],
          currentTarotDeck: state.currentTarotDeck,
          currentDeck: state.currentDeck,
          currentTarotBack: {
            0: CARD_BACKS.original_deck[0],
            1: CARD_BACKS.original_deck[1],
            2: CARD_BACKS.original_deck[2],
          },
          //   timeRemaining: 0,
          currentTime: {
            hours: 0,
            minutes: 0,
            seconds: 0,
          },
          startText: {
            0: "Begin Reading",
            1: "Next Card",
            2: "Next Card",
            3: "Interpretation",
          },
          cardBorderColor: BORDER_GRADIENTS[0].gradient,
          hasFlipped: false,
          expand: {
            0: false,
            1: false,
            2: false,
          },
          expandClassName: {
            0: "",
            1: "",
            2: "",
          },
        })
      );
      dispatch({
        type: "setCurrentTime",
        payload: {
          hours: 0,
          minutes: 0,
          seconds: 1,
        },
      });
    } else {
      if (state.toggleInterpretation) {
        dispatch({ type: "setIsOuterFlipped", payload: false });
        dispatch({ type: "setIsStartButtonEnabled", payload: false });
        dispatch({
          type: "setActiveButton",
          payload: { 0: false, 1: false, 2: false, 3: false },
        });
        setTimeout(() => {
          dispatch({ type: "setToggleInterpretation", payload: false });
          dispatch({ type: "setIsStartButtonEnabled", payload: true });
        }, 500);
      } else {
        dispatch({ type: "setIsOuterFlipped", payload: true });
        dispatch({ type: "setToggleInterpretation", payload: true });
        dispatch({ type: "setToggleOptions", payload: false });
        dispatch({ type: "setToggleFAQ", payload: false });
        dispatch({ type: "setToggleShare", payload: false });
        dispatch({
          type: "setActiveButton",
          payload: { 0: false, 1: true, 2: false, 3: false },
        });
      }
    }
  };

  const handleTarotCardClick = (index) => {
    function addSpinningToElement(ref) {
      ref.current.classList.add("spinning");
      setTimeout(() => {
        ref.current.classList.remove("spinning");
      }, 2000);
    }

    const cardRef =
      index === 0 ? cardOneRef : index === 1 ? cardTwoRef : cardThreeRef;
    const placeholderRef =
      index === 0
        ? placeholderOneRef
        : index === 1
        ? placeholderTwoRef
        : placeholderThreeRef;

    if (state.activeCards[index]) {
      addSpinningToElement(cardRef);
    } else {
      addSpinningToElement(placeholderRef);
    }
  };

  const handleRevealedTarotCardClick = (index) => {
    dispatch({
      type: "setIsFlipped",
      payload: { ...state.isFlipped, [index]: !state.isFlipped[index] },
    });
    dispatch({
      type: "setHasFlipped",
      payload: true,
    });
  };

  const handleSetup = () => {
    dispatch({ type: "setSetup", payload: false });
    handleTarotReadingSetup()
    // dispatch({ type: "setChosenDeck", payload: deck });
    // dispatch({ type: "setCurrentDeck", payload: state.currentTarotDeck });
  }

  const handleToggleFAQ = () => {
    if (state.toggleFAQ) {
      dispatch({ type: "setIsFAQButtonEnabled", payload: false });
      dispatch({ type: "setIsOuterFlipped", payload: false });
      dispatch({
        type: "setActiveButton",
        payload: { 0: false, 1: false, 2: false, 3: false },
      });
      setTimeout(() => {
        dispatch({ type: "setToggleFAQ", payload: false });
        dispatch({ type: "setIsFAQButtonEnabled", payload: true });
      }, 500);
    } else {
      dispatch({ type: "setIsOuterFlipped", payload: true });
      dispatch({ type: "setToggleFAQ", payload: true });
      dispatch({ type: "setToggleInterpretation", payload: false });
      dispatch({ type: "setToggleOptions", payload: false });
      dispatch({ type: "setToggleShare", payload: false });
      dispatch({
        type: "setActiveButton",
        payload: { 0: false, 1: false, 2: true, 3: false },
      });
    }
  };

  const handleToggleOptions = () => {
    if (state.toggleOptions) {
      dispatch({ type: "setIsOptionsButtonEnabled", payload: false });
      dispatch({ type: "setIsOuterFlipped", payload: false });
      dispatch({
        type: "setActiveButton",
        payload: { 0: false, 1: false, 2: false, 3: false },
      });
      setTimeout(() => {
        dispatch({ type: "setToggleOptions", payload: false });
        dispatch({ type: "setIsOptionsButtonEnabled", payload: true });
      }, 500);
    } else {
      dispatch({ type: "setIsOuterFlipped", payload: true });
      dispatch({ type: "setToggleOptions", payload: true });
      dispatch({ type: "setToggleInterpretation", payload: false });
      dispatch({ type: "setToggleFAQ", payload: false });
      dispatch({ type: "setToggleShare", payload: false });
      dispatch({
        type: "setActiveButton",
        payload: { 0: true, 1: false, 2: false, 3: false },
      });
    }
  };

  const handleToggleShare = () => {
    // dispatch({ type: "setToggleShare", payload: !state.toggleShare });
    // if(state.toggleShare) {
    //   dispatch({ type: "setIsOuterFlipped", payload: false });
    if (state.toggleShare) {
        dispatch({ type: "setIsShareButtonEnabled", payload: false });
        dispatch({ type: "setIsOuterFlipped", payload: false });
        dispatch({
          type: "setActiveButton",
          payload: { 0: false, 1: false, 2: false, 3: false },
        });
        setTimeout(() => {
          dispatch({ type: "setToggleShare", payload: false });
          dispatch({ type: "setIsShareButtonEnabled", payload: true });
        }, 500);
      } else {
        dispatch({ type: "setIsOuterFlipped", payload: true });
        dispatch({ type: "setToggleShare", payload: true });
        dispatch({ type: "setToggleInterpretation", payload: false });
        dispatch({ type: "setToggleFAQ", payload: false });
        dispatch({ type: "setToggleOptions", payload: false });
        dispatch({
          type: "setActiveButton",
          payload: { 0: false, 1: false, 2: false, 3: true },
        });
      }
    };

  const handleBackgroundChange = (background) => {
    dispatch({ type: "setCurrentBackground", payload: background });
  };

  const setNewTarotDeck = (deckName, cardOne, cardTwo, cardThree) => {
    // const deck = TAROT_DECKS[deckName];
    const tarotDeckDict = deckName === "original_deck" ? ORIGINAL_DECK_DICT : deckName === "magical_forest_deck" ? MAGICAL_FOREST_DECK_DICT : ETERNAL_BONES_DECK_DICT;
    // const imageName = (url) => {
    //   return Object.keys(tarotDeckDict).find(key => tarotDeckDict[key] === url);
    // }
    // const name = imageName(cardOne);
    // debugger;
    const newCardOne = tarotDeckDict[cardOne];
    const newCardTwo = tarotDeckDict[cardTwo];
    const newCardThree = tarotDeckDict[cardThree];
    // debugger
    dispatch({
      type: "setCards",
      payload: { newCardOne, newCardTwo, newCardThree },
    });
    dispatch({ type: "setCurrentTarotDeck", payload: deckName });
  };

  const handleTarotDeckChange = (tarotDeck) => {
    // debugger;
    if (tarotDeck === "original_deck") {
      setNewTarotDeck(
        "original_deck",
        state.currentCardNames[0],
        state.currentCardNames[1],
        state.currentCardNames[2]
      );
    } else if (tarotDeck === "magical_forest_deck") {
      setNewTarotDeck(
        "magical_forest_deck",
        state.currentCardNames[0],
        state.currentCardNames[1],
        state.currentCardNames[2]
      );
    } else if (tarotDeck === "eternal_bones_deck") {
      setNewTarotDeck(
        "eternal_bones_deck",
        state.currentCardNames[0],
        state.currentCardNames[1],
        state.currentCardNames[2]
      );
    }
  };

  const changeCardBackImage = (tarotDeck) => {
    if (tarotDeck === "original_deck") {
      return {
        back: CARD_BACKS.original_deck[0],
        sample: CARD_SAMPLES.original_deck,
      };
    } else if (tarotDeck === "magical_forest_deck") {
      return {
        back: CARD_BACKS.magical_forest_deck,
        sample: CARD_SAMPLES.magical_forest_deck,
      };
    } else if (tarotDeck === "eternal_bones_deck") {
      return {
        back: CARD_BACKS.eternal_bones_deck,
        sample: CARD_SAMPLES.eternal_bones_deck,
      };
    }
  };

  const setCurrentTarotBack = (cardBack) => {
    if (typeof cardBack === "object") {
      dispatch({
        type: "setCurrentTarotBack",
        payload: {
          0: cardBack[0],
          1: cardBack[1],
          2: cardBack[2],
        },
      });
      return;
    } else {
      dispatch({
        type: "setCurrentTarotBack",
        payload: {
          0: cardBack,
          1: cardBack,
          2: cardBack,
        },
      });
    }
  };

  useEffect(() => {
    switch (state.currentTarotDeck) {
      case "original_deck":
        setCurrentTarotBack(CARD_BACKS.original_deck);
        break;
      case "magical_forest_deck":
        setCurrentTarotBack(CARD_BACKS.magical_forest_deck);
        break;
      case "eternal_bones_deck":
        setCurrentTarotBack(CARD_BACKS.eternal_bones_deck);
        break;
      default:
        break;
    }
  }, [state.currentTarotDeck]);

  const handleCardBorderColorChange = (cardBorderColor) => {
    dispatch({ type: "setCardBorderColor", payload: cardBorderColor });
  };

  const handleCurrentTime = (hours, minutes, seconds) => {
    const time = { hours, minutes, seconds };
    dispatch({ type: "setCurrentTime", payload: time });
  };

  const handleCurrentDeck = (deck) => {
    dispatch({ type: "setCurrentDeck", payload: deck });
 };

 useEffect(() => {
    if (state.currentTarotDeck === "original_deck") {
      handleCurrentDeck(ORIGINAL_DECK);
    } else if (state.currentTarotDeck === "magical_forest_deck") {
      handleCurrentDeck(MAGICAL_FOREST_DECK);
    } else if (state.currentTarotDeck === "eternal_bones_deck") {
      handleCurrentDeck(ETERNAL_BONES_DECK);
    }
  }, [state.currentTarotDeck]);

  // useEffect(() => {
  //   if (
  //     state.currentTime.hours < 24 &&
  //     localStorage.getItem("readingEndTime")
  //   ) {
  //     const intervalId = setInterval(() => {
  //       if (state.currentTime.seconds < 60) {
  //         dispatch({
  //           type: "setCurrentTime",
  //           payload: {
  //             hours: state.currentTime.hours,
  //             minutes: state.currentTime.minutes,
  //             seconds: state.currentTime.seconds + 1,
  //           },
  //         });
  //       } else if (state.currentTime.minutes < 59) {
  //         dispatch({
  //           type: "setCurrentTime",
  //           payload: {
  //             hours: state.currentTime.hours,
  //             minutes: state.currentTime.minutes + 1,
  //             seconds: 1,
  //           },
  //         });
  //       } else if (
  //         state.currentTime.minutes === 59 &&
  //         state.currentTime.seconds === 60 &&
  //         state.currentTime.hours < 24
  //       ) {
  //         dispatch({
  //           type: "setCurrentTime",
  //           payload: {
  //             hours: state.currentTime.hours + 1,
  //             minutes: 0,
  //             seconds: 1,
  //           },
  //         });
  //       }
  //     }, 1000);
  //     return () => clearInterval(intervalId);
  //   } else if(state.currentTime.hours >= 24 && localStorage.getItem("readingEndTime")){
  //       dispatch({
  //           type: "setCurrentTime",
  //           payload: {
  //           hours: 0,
  //           minutes: 0,
  //           seconds: 0,
  //           },
  //       });
  //       localStorage.removeItem("readingEndTime");
  //       localStorage.removeItem("userState");
  //       window.location.reload(); // this is bad practice, but it works for now
  //   }
  // }, [state.currentTime]);


  const handleFetchTarotReadings = useCallback(async () => {

    if(state.currentCardNames) {
    let majorArcanaCount = 0 
    for(let i = 0; i < 3; i++) {
        if(TAROT_CARD_TYPES[state.currentCardNames[i]] === "Major Arcana") {
            majorArcanaCount += 1
        }
    }
    // console.log("majorArcanaCount", majorArcanaCount)
  
    const prompts = []
    function createPrompt(text, url, tokens, image = false) {
        const prompt = {
            "text" : text,
            "url" : url,
            "tokens" : tokens,
            "image": image
        }
        prompts.push(prompt)
    }
    createPrompt(`Pretend you are a mystic and you specialize in three card tarot readings. You drew ${majorArcanaCount} major arcana cards and  ${3 - majorArcanaCount} minor arcana cards. Give an introduction to the reading where you talk about the significance of drawing that combination of arcana cards. Start with the phrase 'Today's reading'. Ensure the reading concludes with a clear and complete sentence.`, null, 600)
    createPrompt(`Pretend you are a mystic and you specialize in three card tarot readings. Give a detailed description of ${state.currentCardNames[0]} and what this card means. The image provided is the tarot card being used. Ensure the reading concludes with a clear and complete sentence.`, state.cardOne, 300)
    createPrompt(`Pretend you are a mystic and you specialize in three card tarot readings. Give a detailed description of ${state.currentCardNames[1]} and what this card means. The image provided is the tarot card being used. Ensure the reading concludes with a clear and complete sentence.`, state.cardTwo, 300)
    createPrompt(`Pretend you are a mystic and you specialize in three card tarot readings. Give a detailed description of ${state.currentCardNames[2]} and what this card means. The image provided is the tarot card being used. Ensure the reading concludes with a clear and complete sentence.`, state.cardThree, 300)
    createPrompt(`Pretend you are mystic and you specialize in three card tarot readings. Please give me an interpretation of a three card tarot reading that drew the cards, ${state.currentCardNames[0]}, ${state.currentCardNames[1]}, and the ${state.currentCardNames[2]}. Discuss how these cards relate to each other and what they mean together. Start with the phrase 'This three card tarot reading'. Ensure the reading concludes with a clear and complete sentence.`, null, 1000)
    // const newTarotReadingsPromises = prompts.map((prompt) => fetchTarotReadings(prompt));
    // console.log("newTarotReadingsPromises", newTarotReadingsPromises)
    // const resolvedReadings = await Promise.all(newTarotReadingsPromises);
    // console.log("resolvedReadings", resolvedReadings)
    // const resolvedReadingsFlattened = resolvedReadings.flat();
    // console.log("resolvedReadingsFlattened", resolvedReadingsFlattened)
    async function fetchSequentialTarotReadings(prompts) {
      const resolvedReadings = [];
  
      for (const prompt of prompts) {
          const reading = await fetchTarotReadings(prompt); // Assuming fetchTarotReadings is an async function
          resolvedReadings.push(reading);
      }
  
      return resolvedReadings.flat(); // If necessary, depending on your data structure
  }
  
  // Usage
  const resolvedReadingsFlattened = await fetchSequentialTarotReadings(prompts);
  console.log("resolvedReadingsFlattened", resolvedReadingsFlattened)
  
    function sortReadings(readings) {
        const sortedReadings = new Array(5).fill('');
        if(readings) {
          for (const reading of readings) {
            if (reading?.includes("Today's reading")) {
              sortedReadings[0] = reading;
            } else if (reading?.includes('This three card tarot reading')) {
              sortedReadings[4] = reading;
              const prompt = {
                "text": "Pretend you are a mystic and you specialize in three card tarot readings. Here is the current interpretation of the reading. Please use this reading and create an image that symbolizes the text. Do not use images of actual tarot cards. Here is the reading..." + reading, 
                "url": null,
                "tokens": null,
                "image": true
              }
  
            // Ensure fetchTarotReadings is properly returning a Promise that resolves to the image data
            const getImage = async () => {
              try {
                const image = await fetchTarotReadings(prompt);
                return image; // This will be a Promise resolving to whatever fetchTarotReadings returns
              } catch (error) {
                console.error('Error fetching tarot readings:', error);
                return null; // Handle error case, potentially returning null or a default value
              }
            };
  
            const fetchImage = async () => {
              try {
                const image = await getImage(); // Use await to wait for the Promise from getImage
                console.log("IMAGE", image); // This will now log the resolved value
                dispatch({type: "setGeneratedImage", payload: image});
              } catch (error) {
                console.error('Failed to fetch image:', error);
              }
            };
  
            fetchImage(); // Call the async function
  
              
            
  
              // console.log("Image", image)
            } else {
              // Find the first empty slot for card descriptions (1, 2, or 3)
              const emptySlotIndex = sortedReadings.findIndex((element, index) => index >= 1 && index <= 3 && !element);
              if (emptySlotIndex !== -1) {
                sortedReadings[emptySlotIndex] = reading;
              }
            }
          }
        }
       
        return sortedReadings;
      }
      
      const sortedReadings = sortReadings(resolvedReadingsFlattened);

     dispatch({type: "setGeneratedReading", payload: sortedReadings})
    }
    
  }, [state.currentCardNames, state.cardOne, state.cardTwo, state.cardThree])
//   console.log("state.generatedReading", state.generatedReading)

    useEffect(() => {
        if(state.currentCardNames[0] && state.currentCardNames[1] && state.currentCardNames[2] && state.generatedReading.length !== 5) {
            handleFetchTarotReadings()
        }
    }, [state.currentCardNames, handleFetchTarotReadings, state.generatedReading])

  //Mobile Only
  const handleExpand = (index) => {
    if (!state.expand[index]) {
      dispatch({
        type: "setExpand",
        payload: { ...state.expand, [index]: true },
      });
      dispatch({
        type: "setExpandClassName",
        payload: { ...state.expandClassName, [index]: "expand-card" },
      });
    } else {
      dispatch({
        type: "setExpand",
        payload: { ...state.expand, [index]: false },
      });
      dispatch({
        type: "setExpandClassName",
        payload: { ...state.expandClassName, [index]: "" },
      });
    }
  };

  //Mobile Only
  const buttonIsOpaque = () => {
    if (state.expand[0] && !state.expand[1] && !state.expand[2]) {
      return {
        0: false,
        1: true, 
        2: true
      }
    } else if (!state.expand[0] && state.expand[1] && !state.expand[2]) {
      return {
        0: true,
        1: false,
        2: true
      }
    } else if (!state.expand[0] && !state.expand[1] && state.expand[2]) {
      return {
        0: true,
        1: true,
        2: false
      }
    } else {
      return {
        0: false,
        1: false,
        2: false
      }
    }
  }

  return {
    state,
    dispatch,
    handleSetup,
    handleStartClick,
    handleTarotReadingSetup,
    handleTarotCardClick,
    handleRevealedTarotCardClick,
    handleToggleFAQ,
    handleToggleOptions,
    handleToggleShare,
    handleBackgroundChange,
    handleTarotDeckChange,
    changeCardBackImage,
    handleCardBorderColorChange,
    handleCurrentTime,
    handleExpand,
    buttonIsOpaque,
    placeholderOneRef,
    placeholderTwoRef,
    placeholderThreeRef,
    cardOneRef,
    cardTwoRef,
    cardThreeRef,
  };
}
