export default function Interpretation({ handleStartClick, state }) {

  const formatCardName = (name) => {
    const words = name.match(/[A-Z][a-z]*/g);
    const separatedWords = words.join(" ");
    return separatedWords
  }
  console.log("state in Interpretation", state)
    // console.log("sSTATE OF GENERATED READING", state.generatedReading)

  //   function splitTextIntoChunks(text, numberOfChunks) {
  //     const chunkLength = Math.ceil(text.length / numberOfChunks);
  //     const chunks = [];
  
  //     for (let i = 0; i < numberOfChunks; i++) {
  //         const start = i * chunkLength;
  //         const end = start + chunkLength;
  //         const chunk = text.slice(start, end);
  //         chunks.push(chunk);
  //     }
  
  //     // Optional: Fine-tuning to ensure the text is not split mid-word
  //     for (let i = 0; i < chunks.length - 1; i++) {
  //         const lastSpaceIndex = chunks[i].lastIndexOf(' ');
  //         if (lastSpaceIndex !== -1 && lastSpaceIndex !== chunks[i].length - 1) {
  //             // Move the partial word to the next chunk
  //             chunks[i + 1] = chunks[i].slice(lastSpaceIndex + 1) + chunks[i + 1];
  //             chunks[i] = chunks[i].slice(0, lastSpaceIndex);
  //         }
  //     }
  
  //     return chunks;
  // }

  // const numberOfChunks = 4;

  // const chunks = splitTextIntoChunks(state.generatedReading[4], numberOfChunks);
  return (
    <div className="interpretation-container">
    <div onClick={() => handleStartClick()} className="close-button">X</div>

      <h1 className="interpretation-header">Interpretation</h1>

      {state.generatedReading ? (
        <>
        <p style={{fontSize: '18px', lineHeight: '25px'}}className="interpretation-text text-top">
                {state.generatedReading[0]}..
        </p>  
            <p style={{fontSize: '32px', textDecoration: 'underline'}}>{`${formatCardName(state.currentCardNames[0])}`}</p>
            <div style={{display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: 'center', gap: '20px'}}>
                <img style={{width: 'auto', height: '450px'}}src={state.cardOne} alt="Card One"></img>
              <p style={{maxWidth: '600px', fontSize: '18px', lineHeight: '25px'}} className="interpretation-text">{`${state.generatedReading[1]}`}</p>
            </div>
            <p style={{fontSize: '32px', textDecoration: 'underline'}}>{`${formatCardName(state.currentCardNames[1])}`}</p>
            <div style={{display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: 'center', gap: '20px'}}>
              <p style={{maxWidth: '600px', fontSize: '18px', lineHeight: '25px'}} className="interpretation-text">{`${state.generatedReading[2]}`}</p>
              <img style={{width: 'auto', height: '450px'}}src={state.cardTwo} alt="Card Two"></img>
            </div>
            <p style={{fontSize: '32px', textDecoration: 'underline'}}>{`${formatCardName(state.currentCardNames[2])}`}</p>
            <div style={{display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: 'center', gap: '20px'}}>
                <img style={{width: 'auto', height: '450px'}}src={state.cardThree} alt="Card Three"></img>
              <p style={{maxWidth: '600px', fontSize: '18px', lineHeight: '25px'}} className="interpretation-text">{`${state.generatedReading[3]}`}</p>
            </div>
            <br/>
            {/* <p className="interpretation-text">{`${formatCardName(state.currentCardNames[1])} - ${state.generatedReading[2]}`}</p>
            <p className="interpretation-text">{`${formatCardName(state.currentCardNames[2])} - ${state.generatedReading[3]}`}</p> */}
            <h1 className="interpretation-header">Conclusion</h1>
            <p style={{fontSize: '18px', lineHeight: '30px'}} className="interpretation-text">{state.generatedReading[4]}</p> 
            <h1 className="interpretation-header">Reveal</h1>
            <img className="generated-image" src={state.generatedImage} alt="Tarot Readings Symbolism"></img>
            <p style={{textAlign: 'center'}} className="interpretation-text">Your special image is only accessible for one hour, so be sure to save it here or from the Save/Share tab!</p> 
            {/* <p style={{fontSize: '18px', columnCount: 3}}className="interpretation-text">{`${state.generatedReading[4]}`}</p>  */}
            {/* <br/> */}
            {/* <p style={{fontSize: '18px'}}className="interpretation-text">{`${chunks[0]}`}</p> 
            <div style={{display: 'flex', flexDirection: "row", justifyContent: 'center', gap: '20px', alignItems: 'center'}}>
              <p style={{fontSize: '18px', width: '210px'}}className="interpretation-text">{`${chunks[1]}`}</p>
              <img className="generated-image" src={state.generatedImage}></img>
              <p style={{fontSize: '18px', width: '210px'}}className="interpretation-text">{`${chunks[2]}`}</p>
            </div>
            <p style={{fontSize: '18px'}}className="interpretation-text">{`${chunks[3]}`}</p> */}
            <br/>
            <br/>
        </>
        ) : (
            <>
            <p className="interpretation-text text-top">
            {state.interpretation && state.interpretation.intro}
          </p>
          <p className="interpretation-text">{`${formatCardName(state.currentCardNames[0])} - ${state.interpretation && state.interpretation.cardOne}`}</p>
          <p className="interpretation-text">{`${formatCardName(state.currentCardNames[1])} - ${state.interpretation && state.interpretation.cardTwo}`}</p>
          <p className="interpretation-text">{`${formatCardName(state.currentCardNames[2])} - ${state.interpretation && state.interpretation.cardThree}`}</p>
          <p className="interpretation-text">{state.interpretation && state.interpretation.outro}</p>
          {/* <img className="generated-image" src={state.generatedImage}></img> */}

            </>
        )
     }
    </div>
  );
}
