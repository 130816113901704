import { ORIGINAL_DECK_LOCAL, MAGICAL_FOREST_DECK_LOCAL, ETERNAL_BONES_DECK_LOCAL } from "../constants";

export default function TarotDownload({ state }) {

  const str = state.cardBorderColor && state.cardBorderColor;
  const newBackgroundGradient = str.replace(" 1", "");
  console.log("State in TarotDownload", state)

  const currentDeck = state.currentTarotDeck === "original_deck" ? ORIGINAL_DECK_LOCAL : state.currentTarotDeck === "magical_forest_deck" ? MAGICAL_FOREST_DECK_LOCAL : ETERNAL_BONES_DECK_LOCAL;
  // debugger;
  //ORIGINALLY TRIED TO HAVE A BORDER IMAGE THAT WAS A LINEAR GRADIENT,
  //the issue is that html2canvas does not support border-image property,
  //SO I changed the border image to border background, and will use the border-image gradient they choose as the background color for the shareable element
  return (
    <div
      style={{ background: newBackgroundGradient }}
      id="reading-area"
      className="share-container"
    >
      <h1 className="card-download-title">
        Daily Tarot Reader
      </h1>
      <div className="card-container-shareable">
        <img
          className={`card-download one`}
          src={currentDeck[state.currentCardNames[0]]}
          alt={"tarot card"}
        ></img>
        <img
          className={`card-download two`}
          src={currentDeck[state.currentCardNames[1]]}
          alt={"tarot card"}
        ></img>
        <img
          className={`card-download Three`}
          src={currentDeck[state.currentCardNames[2]]}
          alt={"tarot card"}
        ></img>
      </div>
    </div>
  );
}
