export default function ButtonContainer({
  state,
  handleToggleOptions,
  handleStartClick,
  handleToggleFAQ,
  handleToggleShare,
  expand,
}) {

//   console.log(state.generatedReading)

  return (
    <div className="button-container-column">
      <div className="button-container-row">
        <button
          disabled={!state.isOptionsButtonEnabled}
          onClick={() => handleToggleOptions()}
          className="button options-button"
          style={{
            backgroundColor: state.activeButton[0] && "rgb(214, 158, 3)",
            color: state.activeButton[0] && "rgb(24, 3, 67)",
          }}
        >
          Options
        </button>
        <button
          disabled={
            (!state.activeCards[0] && localStorage.getItem("readingEndTime")) ||
            !state.isStartButtonEnabled ||
            (state.toggleFAQ && !state.activeCards[2]) ||
            (state.toggleOptions && !state.activeCards[2])
          }
          onClick={() => handleStartClick()}
          className="button start-button"
          style={{
            backgroundColor: state.activeButton[1] && "rgb(214, 158, 3)",
            color: state.activeButton[1] && "rgb(24, 3, 67)",
          }}
        >
          {state.startText[state.startTextIndex]}
        </button>
        {/* <button
          disabled={!state.isFAQButtonEnabled}
          onClick={() => handleToggleFAQ()}
          className="button faq-button"
          style={{
            backgroundColor: state.activeButton[2] && "rgb(214, 158, 3)",
            color: state.activeButton[2] && "rgb(24, 3, 67)",
          }}
        >
          FAQ
        </button> */}
        <button
            onClick={() => handleToggleShare()}
            disabled={!state.activeCards[2]}
            className="button share-button"
        >
            Save/Share
        </button>
      </div>
      {/* <div className="button-container-row">
        <button
            onClick={() => handleToggleShare()}
            disabled={!state.activeCards[2]}
            className="button share-button"
        >
            Save/Share
        </button>
      </div> */}
    </div>
  );
}
