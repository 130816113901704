import { useState } from "react";
import "./Share.css";
import {
  downloadAsPDF,
  downloadAsWord,
  downloadImageFromHTML,
} from "../helpers";
import pdf_example from "../images/pdf_example.png";
import word_example from "../images/word_example.png";
export default function Share({ state, handleToggleShare }) {
  const [downloadType, setDownloadType] = useState("pdf");

  const handleDownload = (state) => {
    if (downloadType === "pdf") {
      downloadAsPDF(state.generatedReading, state);
    } else if (downloadType === "word") {
      downloadAsWord(state.generatedReading);
    }
  };

  const handleRadioChange = (event) => {
    setDownloadType(event.target.value);
  };

  const downloadImage = () => {
    const a = document.createElement('a');
    a.href = state.generatedImage; // The file to download
    a.download = "DownloadedImage.png"; // The filename you want to save as
    a.target = '_blank'; // Open in a new tab
    document.body.appendChild(a); // Required for this to work in FireFox
    a.click();
    document.body.removeChild(a);
  };  

  const str = state.cardBorderColor && state.cardBorderColor;
  const newBackgroundGradient = str.replace(" 1", "");

  return (
    <div className="download-container">
      <div onClick={() => handleToggleShare()} className="close-button">
        X
      </div>
      <h1 className="share-header">Download and Share!</h1>
      <div className="download-column">
        <div className="download-row">
          <div style={{ marginRight: 30 }} className="download-column">
            <div  style={{
              border: "10px solid transparent",
              borderImage: state.cardBorderColor,
            }}>
              <img style={{height: '200px'}}src={state.generatedImage} alt="Generated Tarot Reading Symbolism"></img>
            </div>
            <button className="button" onClick={downloadImage}>
              Download Image
            </button>
          </div>
          <div style={{ marginRight: 30 }} className="download-column">
            <div
              style={{ background: newBackgroundGradient }}
              className="share-and-download-container"
            >
              <div className="transform-container">
                <h1 className="card-download-title">Daily Tarot Reader</h1>
                <div className="card-container-shareable">
                  <img
                    className={`card-download one`}
                    src={state.cardOne}
                    alt={"tarot card"}
                  ></img>
                  <img
                    className={`card-download two`}
                    src={state.cardTwo}
                    alt={"tarot card"}
                  ></img>
                  <img
                    className={`card-download Three`}
                    src={state.cardThree}
                    alt={"tarot card"}
                  ></img>
                </div>
              </div>
            </div>
            <button className="button" onClick={downloadImageFromHTML}>
              Download Image
            </button>
          </div>
        
          <div className="download-column">
            <div style={{
              border: "10px solid transparent",
              borderImage: state.cardBorderColor,
            }}>
            {downloadType === "pdf" ? (
              <img
                className="text-examples"
                src={pdf_example}
                alt="pdf example"
              />
            ) : (
              <img
                className="text-examples"
                src={word_example}
                alt="word example"
              />
            )}
            </div>
            <div className="download-column">
              <div className="download-row">
                <label>
                  <input
                    type="radio"
                    value="pdf"
                    checked={downloadType === "pdf"}
                    onChange={handleRadioChange}
                  />
                  PDF
                </label>
                <label>
                  <input
                    type="radio"
                    value="word"
                    checked={downloadType === "word"}
                    onChange={handleRadioChange}
                  />
                  Word
                </label>
              </div>
              <button className="button" onClick={() => handleDownload(state)}>
                Download Text
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
