import "./About.css";
export default function About() {
  return (
    <div className="about-container">
      <h1 className="about-header">About</h1>
      <p className="about-text">
        <span role="img" aria-label="sparkles">
          ✨
        </span>{" "}
        Welcome to{" "}
        <span className="about-important-text">Daily Tarot Reader!</span> Our
        mission is to help you find <span className="underline">guidance,</span>
        , <span className="underline">clarity</span> and,{" "}
        <span className="underline">insight</span> through the ancient art of
        tarot reading.{" "}
        <span role="img" aria-label="crystal-ball">
          🔮
        </span>
        <br />
        <br />
        Our app provides a <span className="underline">simple</span>,{" "}
        <span className="underline">easy-to-use</span> platform for accessing
        tarot readings from the comfort of your own home. Once a day, our app
        generates a three-card tarot reading for you, giving you insight into
        your <span className="about-important-text past">past</span>,{" "}
        <span className="about-important-text present">present</span>, and{" "}
        <span className="about-important-text future">future</span>.{" "}
        <span role="img" aria-label="three-cards">
          🃏🃏🃏
        </span>
        <br />
        <br />
        We <span className="underline">believe</span> in the{" "}
        <span className="underline">power</span> of tarot to provide direction
        and support in navigating life's challenges. Our team of experienced
        tarot readers has carefully curated a selection of decks that reflect a
        variety of perspectives and traditions, ensuring that you have access to
        the right deck to meet your needs.{" "}
        <span role="img" aria-label="thumbs-up">
          👍
        </span>
        <br />
        <br />
        At <span className="about-important-text">Daily Tarot Reader</span>,
        we're committed to providing a safe and supportive space for everyone to
        explore the mysteries of tarot. Whether you're a seasoned tarot
        enthusiast or just getting started, we{" "}
        <span className="underline">welcome</span> you to join our community and
        experience the <span className="underline">magic</span> of tarot for
        yourself.{" "}
        <span role="img" aria-label="heart">
          ❤️
        </span>
        <br />
        <br />
        Thank you for choosing{" "}
        <span className="about-important-text">Daily Tarot Reader</span>! We
        look forward to being a part of your journey.{" "}
        <span role="img" aria-label="star">
          ⭐️
        </span>
      </p>
    </div>
  );
}
